//child of SideBarNav
import React from 'react'
import './SidebarAuthorContact.scss'
import SectionTitle from '../../SectionTitle/SectionTitle'
import Socials from '../../Socials/Socials'

function SidebarAuthorContact({ author, closeContent }) {

    return (
        <>
            <SectionTitle sectionTitle={author.name} />
            <Socials author={author} color={'gold'} closeContent={closeContent} socialsClass={'socials__icons'} showEmail={true}/>
        </>
    )
}

export default SidebarAuthorContact