import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { getStack, getFeaturedProjects } from '../../helpers/utils';
import { useWindowSize } from '../../helpers/useWindowSize';
import { useScrollToSection } from '../../helpers/useScrollToSection';
import './Author.scss';

import Header from '../../components/Header/Header';
import Hero from '../../components/Hero/Hero';
import TechStack from '../../components/TechStack/TechStack';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import AboutAuthor from '../../components/AboutAuthor/AboutAuthor';
import FeaturedProjects from '../../components/FeaturedProjects/FeaturedProjects';
import Experience from '../../components/Experience/Experience';
import Contact from '../../components/Contact/Contact';
import SocialsSide from '../../components/SocialsSide/SocialsSide';
import Footer from '../../components/Footer/Footer';
import ExperienceDetailsSide from '../../components/ExperienceDetailsSide/ExperienceDetailsSide';
import Button from '../../components/Button/Button';

import authors from '../../data/authors.json';
import contacts from '../../data/contact.json';


function Author() {

    //check for screen size
    const isDesktop = useWindowSize();
    const isTablet = useWindowSize(768);

    //header nav items in this page
    useScrollToSection();
    const authorNavItems = [
        { itemName: 'About', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#author-about' },
        { itemName: 'Projects', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#author-projects' },
        { itemName: 'Experience', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#author-experience' },
        { itemName: 'Contact', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#author-contact-form' },
        { itemName: 'Back to Home', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/' }
    ]

    const { ref: myRef, inView: isVisible } = useInView();

    // getting author data based on tag in params
    const { author } = useParams();
    const authorData = authors.find(a => a.tag.toLowerCase() === author);
    // getting author contact data
    const contactData = contacts.find(contact => contact.name === authorData.name)

    // getting a reference to author's stack
    const techData = getStack(authorData.stack);
    //getting a reference to author's featured projects
    const featuredData = getFeaturedProjects(authorData.featuredProjects);

    //go to author's projects
    const navigate = useNavigate();
    const goToAuthorProjects = () => {
        navigate(`/${authorData.tag.toLowerCase()}/projects`);
    }

    // experience accordion controls
    const [clicked, setClicked] = useState(null);
    const [experienceIndex, setExperienceIndex] = useState(0);

    useEffect(() => {
        setClicked(isTablet ? experienceIndex : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTablet]);

    const isClicked = (index) => {
        setExperienceIndex(index);
        if (clicked === index && !isTablet) {
            return setClicked(null)
        }
        setClicked(index)
    };

    return (
        <div className='author'>
            <Header navItems={authorNavItems} />
            <Hero author={authorData} />
            <Link to='./#author-contact-form' className={isDesktop ? 'hidden' : 'author__linkto'}>
                <Button className={isDesktop ? 'hidden' : 'button-transparent hero__button'} buttonText={'Get In Touch'} />
            </Link>
            <SectionTitle sectionTitle={'About'} sectionTitleId={'author-about'} />
            <AboutAuthor summary={authorData.summary} />
            <SectionTitle sectionTitle={'Skills'} />
            <TechStack stackContent={techData} />
            <SectionTitle sectionTitle={'Featured Projects'} sectionTitleId={'author-projects'} />
            {featuredData.map((project, index) => (
                <FeaturedProjects
                    projectData={project}
                    FeaturedProjectsClass={'featuredprojects'}
                    key={index} />
            ))}
            <Button className={'button-gold'} buttonText={'View All Projects'} action={goToAuthorProjects} />
            <SectionTitle sectionTitle={'Experience'} sectionTitleId={'author-experience'} />
            {/* experience-section--tablet places the content of each experience next to the company name on the right */}
            <div className='experience-section--tablet'>
                <div className='experience-companies'>
                    {authorData.workExperience.map((experience, index) => (
                        <Experience
                            experience={experience}
                            key={index}
                            index={index}
                            isClicked={isClicked}
                            clickedState={clicked} />
                    ))}
                </div>
                <ExperienceDetailsSide experience={authorData.workExperience[experienceIndex]} />
            </div>
            <SectionTitle sectionTitle={'Get In Touch'} sectionTitleId={'author-contact-form'} />
            <Contact sendToOption={contactData.tag} />
            <SocialsSide isVisible={isVisible} author={authorData} />
            <Footer myRef={myRef} />
        </div>
    )
}

export default Author