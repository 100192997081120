import React, { useState } from 'react';
import { useScrollToSection } from '../../helpers/useScrollToSection';
import './Projects.scss';

import Header from '../../components/Header/Header';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import FilterBy from '../../components/FilterBy/FilterBy';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import ProjectCardReverse from '../../components/ProjectCardReverse/ProjectCardReverse';

import projects from '../../data/projects.json';

function Projects() {

    const [filter, setFilter] = useState(false);
    const showFilter = () => setFilter(true);
    const closeFilter = () => setFilter(false);

    //header nav items in this page
    useScrollToSection();
    const homeNavItems = [
        { itemName: 'Projects', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/projects' },
        { itemName: 'About', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/#about-authors' },
        { itemName: 'Contact', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/#home-contact-form' }
    ]

    // handling projects displayed based on filter parameters
    const [projectsDisplayed, setProjectsDisplayed] = useState(projects);

    const handleFilters = (filters) => {
        let projectsFiltered = projects;
        for (let key in filters) {
            projectsFiltered = projectsFiltered.filter(function (proj) {
                if (filters[key] === "") {
                    return true;
                }
                else if (proj[key].includes(filters[key])) {
                    return true;
                }
                return false;
            });
        }

        setProjectsDisplayed(projectsFiltered);
    }

    return (
        <div className='projects'>
            <Header navItems={homeNavItems} />
            <SectionTitle sectionTitle={'Projects'} />
            {
                projectsDisplayed.length > 0 ?
                    projectsDisplayed.map((project, index) => {
                        return (index % 2 === 0) ? <ProjectCard project={project.tag} /> : <ProjectCardReverse project={project.tag} />
                    })
                    : (<p>No project found with the applied filters</p>)
            }

            <div className={filter ? 'projects__placeholder active' : 'projects__placeholder'}></div>
            <FilterBy handleFilters={handleFilters} filter={filter} showFilter={showFilter} closeFilter={closeFilter} />
        </div>

    )
}

export default Projects