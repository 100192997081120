// child of the Carousel component
// renders the current slide
import React from 'react';
import { getStack } from '../../../helpers/utils';
import { useWindowSize } from '../../../helpers/useWindowSize.js';
import { useNavigate } from "react-router-dom";
import "./CarouselSlide.scss";

import Button from '../../Button/Button';
import TechStack from '../../TechStack/TechStack';


function CarouselSlide({ current }) {

    // check for desktop screen size
    const isDesktop = useWindowSize();

    // getting a reference to projects's stack
    const techData = getStack(current.stack);

    // handling navigation to a individual project page
    const navigate = useNavigate();
    const goToProject = () => {
        navigate(`/projects/${current.tag.toLowerCase()}`);
    }

    return (
        <div className='carousel__slide'>
            <div className='carousel__slide-image'>
                <img src={current.imageLaptop.image} alt={current.imageLaptop.alt} className={`${isDesktop ? 'hidden' : 'laptop'}`} />
                <img src={current.imageDevices.image} alt={current.imageDevices.alt} className={`${isDesktop ? 'laptop' : 'hidden'}`} />
            </div>
            <div className='carousel__slide-description'>
                <h2 className='carousel__slide-description-title'>{current.name}</h2>
                <p className='carousel__slide-description-paragraph'>{current.shortDescription}</p>
                <TechStack stackContent={techData} />
                <Button className={'button-transparent button-carousel'} buttonText={'View Project'} action={goToProject} />
            </div>

        </div>
    )
}

export default CarouselSlide