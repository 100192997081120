//child of Header
import React from 'react';
import "./Navbar.scss";
import NavbarItem from './NavbarItem/NavbarItem';

function Navbar({ navItems }) {

  return (
    <div className='navbar'>
      {navItems.map((navItem, index) => (
        <NavbarItem itemClass={navItem.itemClass} itemName={navItem.itemName} pathTo={navItem.pathTo} key={index} />
      ))}
    </div>
  )
}

export default Navbar