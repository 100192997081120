//about section in individual author page
//child of Author component
import React from 'react';
import './AboutAuthor.scss';

function AboutAuthor({summary}) {
    return (
        <div className='aboutauthor'>
            <p>{summary}</p>
        </div>
    )
}

export default AboutAuthor