import React from 'react';
import './Input.scss';

function Input({ InputClassName, type, placeholderText, name, state, setState }) {

  const handleChange = (event) => {
    setState(event.target.value);
  };


  return (
    <>
      <input
        className={InputClassName}
        type={type}
        placeholder={placeholderText}
        name={name}
        value={state}
        onChange={handleChange}
      ></input>
    </>
  )
}

export default Input