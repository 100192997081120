//child of Header
import React, { useState } from 'react';
import "./HeaderMenuButton.scss";
import menuGold from "../../../assets/icons/menu-gold.svg";
import SideBar from "../../SideBar/SideBar";

function HeaderMenuButton({ navItems }) {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <div className="header__menu">
            <button className="header__menu-button" onClick={showSidebar}>
                <img src={menuGold} className="header__menu-button-img" alt='menu icon' />
            </button>
            <SideBar sidebar={sidebar} showSidebar={showSidebar} navItems={navItems} />
        </div>
    )
}

export default HeaderMenuButton