//child of FilterBy -> deals with filters behaviour
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import './FilterByContainer.scss';
import SelectField from '../SelectField/SelectField';
import Input from '../Input/Input';
import CloseButton from '../CloseButton/CloseButton';
import closeIconGold from "../../assets/icons/close-gold.svg";

import authors from "../../data/authors.json";
import techs from "../../data/techStack.json";

function FilterByContainer({ filter, closeFilter, handleFilters }) {

    // getting author data based on tag in params
    const { author } = useParams();

    //filters states
    const [authorFilter, setAuthorFilter] = useState(author ? author : '');
    const [techFilter, setTechFilter] = useState("");
    const [projectNameFilter, setProjectNameFilter] = useState("");

    useEffect(() => {
        handleFilters({authors: authorFilter, stack: techFilter, nameLC: projectNameFilter.toLowerCase()});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorFilter, techFilter, projectNameFilter])

    //create author filter options to select field
    const authorOptions = [];
    for(let author of authors) {
        const option = {value: author.nameLC, text: author.name};
        authorOptions.push(option);
    }
    //create techs filter options to select field
    const techOptions = [];
    for(let tech of techs) {
        const option = {value: tech.tag, text: tech.name};
        techOptions.push(option);
    }

    return (
        <div className={filter ? "fbc active" : "fbc"}>
            <CloseButton iconColor={closeIconGold} closeContent={closeFilter}/>
            <SelectField
                selectFieldClassName={'fbc__select-author'}
                placeholderSelectField={'All Authors'}
                options={authorOptions}
                name={'authorsFilter'}
                state={authorFilter}
                setState={setAuthorFilter}
                validationState={true}
            />
            <SelectField
                selectFieldClassName={'fbc__select-author'}
                placeholderSelectField={'All Languages'}
                options={techOptions}
                name={'techsFilter'}
                setState={setTechFilter}
                validationState={true}
            />
            <Input
                InputClassName={`fbc__select-author`}
                placeholderText={'By Project Name'}
                name={'projectsFilter'}
                state={projectNameFilter}
                setState={setProjectNameFilter}
                validationState={true}
            />
        </div>
    )
}

export default FilterByContainer