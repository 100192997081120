//child of Projects page
import React from 'react';
import { useNavigate } from "react-router-dom";
import { getStack, getProject } from '../../helpers/utils';
import { useWindowSize } from '../../helpers/useWindowSize.js';
import './ProjectCardReverse.scss';

import Button from '../Button/Button';
import TechStack from '../TechStack/TechStack';

function ProjectCardReverse({project}) {

    // check for desktop screen size
    const isDesktop = useWindowSize();

    // getting project information
    const projectData = getProject(project.toLowerCase());
    // getting a reference to projects's stack
    const techData = getStack(projectData.stack);

    // handling navigation to a individual project page
    const navigate = useNavigate();
    const goToProject = () => {
        navigate(`/projects/${projectData.tag.toLowerCase()}`);
    }

    return (
        <div className='project-card-reverse'>
            <div className={`${isDesktop ? 'hidden' : 'project-card-reverse__image--mobile'}`}>
                <img src={projectData.imageLaptop.image} alt={projectData.imageLaptop.alt} className={'project-card-reverse__image-img--mobile'} />
            </div>
            <div className='project-card-reverse__description'>
                <h2 className='project-card-reverse__description-title'>{projectData.name}</h2>
                <p className='project-card-reverse__description-paragraph'>{projectData.shortDescription}</p>
                { <TechStack stackContent={techData}/> }
                <Button className={'button-transparent button-carousel'} buttonText={'View Project'} action={goToProject} />
            </div>
            <div className={`${isDesktop ? 'project-card-reverse__image' : 'hidden'}`}>
                <img src={projectData.imageLaptop.image} alt={projectData.imageLaptop.alt} className={'project-card-reverse__image-img'}/>
            </div>
        </div>
    )
}

export default ProjectCardReverse