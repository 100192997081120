//child of TechStack
import React from 'react';
import './TechStackIcon.scss';

function TechStackIcon(props) {
    return (
        <div className={props.techStackIconContainerClass}>
            <div className={props.techStackIconImageClass}>
                <img src={props.techStackIcon} className={props.techStackIconClass} alt={props.techStackIconAlt} />
            </div>
            <p className={props.techStackIconNameClass}>{props.techStackIconName}</p>
        </div>
    )
}

export default TechStackIcon