import React from 'react';
import './TextArea.scss';

function TextArea({ textAreaClassName, placeholderTextArea, name, state, setState }) {

  const handleChange = (event) => {
    setState(event.target.value);
  };


  return (
    <>
      <textarea
        className={textAreaClassName}
        type='text'
        placeholder={placeholderTextArea}
        name={name}
        value={state}
        onChange={handleChange}
      ></textarea>
    </>
  )
}

export default TextArea