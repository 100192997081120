//child of SideBar
import React from 'react'
import './SidebarNav.scss'
import SidebarNavItem from '../SidebarNav/SidebarNavItem/SidebarNavItem'

function SidebarNav({ navItems, closeContent }) {
    return (
        <div className='sidebar-nav'>
            {navItems.map((navItem, index) => (
                <SidebarNavItem
                    itemClass={navItem.sidebarItemClass}
                    itemName={navItem.itemName}
                    pathTo={navItem.pathTo}
                    closeContent={closeContent}
                    key={index}
                />
            ))}
        </div>
    )
}

export default SidebarNav