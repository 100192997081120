//child of Author page
import React from 'react'
import './SocialsSide.scss'

import Socials from '../Socials/Socials';

function SocialsSide({ isVisible, author }) {

    return (
        <div className={isVisible ? 'hidden' : 'socialside'}>
            <Socials author={author} color={'silver'} showEmail={false} socialsClass={'socialside__socials'} socialIconLinkClass={'socialside__socials-link'} />
        </div>
    )
}

export default SocialsSide