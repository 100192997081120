//child of sidebar
//child of filterbycontainer
import React from 'react';
import "./CloseButton.scss";



function CloseButton({ closeContent, iconColor }) {

    const handleClick = (e) => {
        e.stopPropagation();
        closeContent();
    }
    return (
        <button className="close-button" onClick={handleClick}>
            <img className="close-button__image" src={iconColor} alt="close-icon" />
        </button>
    )
}

export default CloseButton