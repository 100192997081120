//child of Experience
import React from 'react';
import './PlusButton.scss';

function PlusButton(props) {
    return (
        <div className={props.plusButtonClass}>
            <img src={props.plusButtonIcon} className={props.plusButtonIconClass} alt='plus sign inside a circle' />
        </div>
    )
}

export default PlusButton