//child of Author page
import React from 'react';
import './Hero.scss';

import AuthorImage from '../AuthorImage/AuthorImage';
import Button from '../Button/Button';
import { useWindowSize } from '../../helpers/useWindowSize';
import { Link } from 'react-router-dom';

function Hero({ author }) {

    const isDesktop = useWindowSize();

    const profilePicture = require(`../../assets/images/${author.profilePictureFilename}`)
    return (
        <div className='hero'>
            <div className='hero__presentation'>
                <h3 className='hero__presentation-hi'>Hi, my name is</h3>
                <h1 className='hero__presentation-name'>{author.name}</h1>
                <h3 className='hero__presentation-title'>{author.title}</h3>
                <Link to='./#author-contact-form'>
                    <Button className={isDesktop ? 'button-transparent hero__button' : 'hidden'} buttonText={'Get In Touch'} />
                </Link>
            </div>
            <AuthorImage
                authorImageClass={'hero__author-image'}
                authorImageImgClass={'hero__author-image-img'}
                authorImage={profilePicture}
            />
        </div>
    )
}

export default Hero