import React from 'react';
import { useParams } from 'react-router-dom';
import { useScrollToSection } from '../../helpers/useScrollToSection';
import './IndividualProject.scss';
import Header from '../../components/Header/Header';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Banner from '../../components/Banner/Banner';
import ProjectSummary from '../../components/ProjectSummary/ProjectSummary';
import TechStack from '../../components/TechStack/TechStack';
import ProjectFeatures from '../../components/ProjectFeatures/ProjectFeatures';

import { getProject, getStack, getTitledListData } from '../../helpers/utils';
import Footer from '../../components/Footer/Footer';
import MediaCarousel from '../../components/MediaCarousel/MediaCarousel';

import YoutubeEmbed from '../../helpers/YoutubeEmbed'

function IndividualProject() {

  //header nav items in this page
  useScrollToSection();
  const individualProjectNavItems = [
    { itemName: 'Details', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#project-details' },
    { itemName: 'Tech Stack', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#project-stack' },
    { itemName: 'Media', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: './#project-media' },
    { itemName: 'Back to Home', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/' }
  ]

  // getting project data based on tag in params
  const { project } = useParams();
  const projectData = getProject(project);

  // getting a reference to project's stack
  const techData = getStack(projectData.stack);

  // getting possible data for features in titled list format
  // project details
  const detailsData = getTitledListData(projectData.details);
  const detailsTitles = detailsData.titles;
  const detailsLists = detailsData.lists;

  return (
    <div className='individualproject'>
      <Header navItems={individualProjectNavItems} />
      <SectionTitle sectionTitle={projectData.name} sectionTitleExtraClass={'individualproject__section-title'} />
      <Banner project={projectData} />
      <SectionTitle sectionTitle={'Summary'} />
      <ProjectSummary project={projectData} />
      <SectionTitle sectionTitle={'Details'} sectionTitleId={'project-details'} />
      {detailsTitles.length > 0 ?
        detailsTitles.map((title, index) => <ProjectFeatures key={index} featureTitle={title} featureItems={detailsLists[index]} />)
        : <p>Coming soon.</p>
      }
      <SectionTitle sectionTitle={'Tech Stack'} sectionTitleId={'project-stack'} />
      <TechStack stackContent={techData} />
      <SectionTitle sectionTitle={'Media'} sectionTitleId={'project-media'} />
      {projectData.screenshots.length > 0 ? <MediaCarousel media={projectData.screenshots} /> : <p>Coming soon.</p> }
      {projectData.youtubeVideoId ? <YoutubeEmbed embedId={projectData.youtubeVideoId} /> : null }
      <Footer />
    </div>
  )
}

export default IndividualProject