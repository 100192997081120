import React from 'react';
import './TechStack.scss';

import TechStackIcon from '../TechStackIcon/TechStackIcon';

function TechStack({ stackContent, techstackExtraClass }) {
    return (
        <div className={`techstack ${techstackExtraClass}`}>
            {stackContent.map((stackItem, index) => (
                <TechStackIcon
                    key={index}
                    techStackIcon={stackItem.icon}
                    techStackIconContainerClass={'ac__tsi'}
                    techStackIconImageClass={'ac__tsi__img-container'}
                    techStackIconClass={'ac__tsi__img-container__img'}
                    techStackIconNameClass={'ac__tsi__name'}
                    techStackIconAlt={stackItem.altText}
                    techStackIconName={stackItem.techName}
                />
            ))}
        </div>
    )
}

export default TechStack