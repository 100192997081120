import React from 'react';

import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects'
import Author from './pages/Author/Author';
import IndividualProject from './pages/IndividualProject/IndividualProject';


function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/:author" element={<Author />} />
          <Route path="/:author/projects" element={<Projects />} />
          <Route path="/projects/:project" element={<IndividualProject />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
