import React from 'react'
import './EmailIcon.scss'

import { Link } from 'react-router-dom'

import emailIcon from '../../assets/icons/socials/mail.svg'

function EmailIcon({ socialLink, closeContent }) {

    const handleClick = (e) => {
        e.stopPropagation();
        closeContent();
    }

    return (
        <>
            <Link to={socialLink} onClick={handleClick}>
                <img src={emailIcon} alt={'closed envelope icon in gold'} className='social__icon' />
            </Link>
        </>
    )
}

export default EmailIcon