//child of AuthorCard
import React from 'react';
import "./AuthorImage.scss";

function AuthorImage({ authorImageClass, authorImageImgClass, authorImage }) {
  return (
    <div className={authorImageClass}>
      <img src={authorImage} className={authorImageImgClass} alt='profile avatar'/>
    </div>
  )
}

export default AuthorImage