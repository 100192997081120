//child of Individual Project page -> summary section
import React from 'react'
import './ProjectSummary.scss'
import GitHubLink from '../GitHubLink/GitHubLink'


function ProjectSummary({ project }) {


    return (
        <div className='projectsummary'>
            {project.longDescription.map((paragraphText) => <p className='projectsummary__text'>{paragraphText}</p>)}
            {project.gitHubLink ? <GitHubLink gitHubLink={project.gitHubLink} /> : null}
        </div>
    )
}

export default ProjectSummary