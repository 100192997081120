//child of Featured
import React, {useState} from 'react'
import './MediaCarousel.scss'

import chevronPrevious from "../../assets/icons/chevron-previous.svg";
import chevronNext from "../../assets/icons/chevron-next.svg";
import MediaCarouselSlide from '../MediaCarouselSlide/MediaCarouselSlide';

function MediaCarousel({media}) {

    // previous and next slide functions
    const [index, setIndex] = useState(0);

    const nextSlide = () => {
        setIndex(index === media.length - 1 ? 0 : index + 1);
    }
    const previousSlide = () => {
        setIndex(index === 0 ? media.length - 1 : index - 1);
    }

    return (
        <div className="mediacarousel">
            <div className="mediacarousel__container">
                <button className='mediacarouselslide__previous' onClick={previousSlide}>
                    <img src={chevronPrevious} className='mediacarouselslide__previous-image' alt='previous-icon'/>
                </button>
                <MediaCarouselSlide mediaCarouselSlideImage={'mediacarouselslide__image'} current={media[index]} />
                <button className='mediacarouselslide__next' onClick={nextSlide}>
                    <img src={chevronNext} className='mediacarouselslide__next-image' alt='next-icon' />
                </button>
            </div>
            <p className="mediacarousel__description">{media[index].description}</p>
            <div className='mediacarousel__buttons'>
                <button className='mediacarousel__previous' onClick={previousSlide}>
                    <img src={chevronPrevious} className='mediacarousel__previous-image' alt='previous-icon' />
                </button>
                <button className='mediacarousel__next' onClick={nextSlide}>
                    <img src={chevronNext} className='mediacarousel__next-image' alt='next-icon' />
                </button>
            </div>
        </div>
    )
}

export default MediaCarousel