//child of Footer
import React from 'react'
import './FooterAuthorSocials.scss'

import Socials from '../Socials/Socials'

function FooterAuthorSocials({ author }) {
    return (
        <div className='footerauthorsocials'>
            <p className='footerauthorsocials__author-name'>{author.name}</p>
            <Socials author={author} color={'silver'} showEmail={false} socialsClass={'footer__socials'} socialIconImageClass={'footer__socials-image'} />
        </div>
    )
}

export default FooterAuthorSocials