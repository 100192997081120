import React from 'react';
import "./Logo.scss";
import justLogo from "../../assets/logo/logo-transparent.svg";
import { Link } from 'react-router-dom';

function Logo() {
    return (
        <div className='logo'>
            <Link to='/'>
                <img src={justLogo} className='logo__image' alt='just brand logo' />
            </Link>
        </div>
    )
}

export default Logo