//child of Navbar
import React from 'react';
import "./NavbarItem.scss";
import { Link } from 'react-router-dom';


function NavbarItem({ itemName, itemClass, pathTo }) {

    return (
        <>
            <Link className={itemClass} to={pathTo}>
                <span className='from-top'></span>
                <span className='from-right'></span>
                <span className='from-bottom'></span>
                <span className='from-left'></span>
                {itemName}
            </Link>
        </>
    )
}

export default NavbarItem