//child of Author page -> names of the companies
import React from 'react';
import './Experience.scss';
import PlusButton from '../PlusButton/PlusButton';

import plusIcon from '../../assets/icons/plus-gold.svg';
import minusIcon from '../../assets/icons/minus-gold.svg';
import ExperienceDetails from '../ExperienceDetails/ExperienceDetails';

function Experience({ experience, index, isClicked, clickedState }) {

    return (
        <>
            <div className='experience' onClick={() => isClicked(index)}>
                <p className='experience__title'>{experience.company}</p>
                <PlusButton
                    plusButtonClass={'plusminusbutton'}
                    plusButtonIconClass={'plusminusbutton__icon'}
                    plusButtonIcon={clickedState === index ? minusIcon : plusIcon} />
            </div>
            <ExperienceDetails experience={experience} experienceDetailsClass={clickedState === index ? 'experiencedetails active' : 'experiencedetails'} />
        </>
    )
}

export default Experience