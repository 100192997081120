//child of SelectField
import React from 'react';
import "./OptionField.scss";

function OptionField({ optionFieldClassName, value, textValue, disabled, hidden, selected }) {
    return (
        <>
            <option className={optionFieldClassName} value={value} disabled={value === '' ? disabled : null} hidden={value === '' ? hidden : null} selected={value === '' ? selected : null}>{textValue}</option>
        </>
    )
}

export default OptionField