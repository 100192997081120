// child of Featured component
// implements the behaviour of the carrousel for featured projects
import React, { useEffect, useState } from 'react';
import { getFeaturedProjects } from '../../helpers/utils';

import "./Carousel.scss";
import chevronPrevious from "../../assets/icons/chevron-previous.svg";
import chevronNext from "../../assets/icons/chevron-next.svg";
import CarouselSlide from './CarouselSlide/CarouselSlide';

import featuredProjects from "../../data/featuredProjects.json";


function Carousel() {

    //getting a reference to featured projects
    const featuredData = getFeaturedProjects(featuredProjects.featured);

    // previous and next slide functions
    const [index, setIndex] = useState(0);

    const nextSlide = () => {
        setIndex(index === featuredData.length - 1 ? 0 : index + 1);
    }
    const previousSlide = () => {
        setIndex(index === 0 ? featuredData.length - 1 : index - 1);
    }

    // auto slider behaviour -> change to next slide every 3 seconds, unless mouse is over slide
    const [hover, setHover] = useState(false);
    const timeoutRef = React.useRef(null);
    useEffect(() => {
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
        }
        if (!hover) {
            timeoutRef.current = setInterval(nextSlide, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, hover]);


    return (
        <div className="carousel" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {<CarouselSlide current={featuredData[index]} />}
            <div className='carousel__button'>
                <button className='carousel__previous' onClick={previousSlide}>
                    <img src={chevronPrevious} className='carousel__previous-image' alt='previous-icon' />
                </button>
                <button className='carousel__next' onClick={nextSlide}>
                    <img src={chevronNext} className='carousel__next-image' alt='next-icon' />
                </button>
            </div>
        </div>
    )
}

export default Carousel