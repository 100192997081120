//renders section titles
import React from 'react';
import './SectionTitle.scss';

function SectionTitle({ sectionTitle, sectionTitleExtraClass, sectionTitleId }) {
    return (
        <div className='section-title' id={sectionTitleId}>
            <span className='section-title__span'></span>
            <div className='section-title__div'>
                <h2 className={`section-title__title ${sectionTitleExtraClass}`}>{sectionTitle}</h2>
            </div>
            <span className='section-title__span'></span>
        </div>
    )
}

export default SectionTitle