//child of ProjectSummary -> github button that is displayed for projects with public git repo
import React, { useState } from 'react'
import './GitHubLink.scss'


import githubGold from '../../assets/icons/socials/github-gold.svg'
import githubNavy from '../../assets/icons/socials/github-navy.svg'
import { Link } from 'react-router-dom';

function GitHubLink({gitHubLink}) {

  const [onHover, setOnHover] = useState(false);

  function hover() {
    setOnHover(true)
  }

  function notHover() {
    setOnHover(false)
  }

  function delayedHover() {
    setTimeout(hover, 250)
  }

  function delayedNotHover() {
    setTimeout(notHover, 400)
  }

  return (
    <Link className='githublink' to={gitHubLink} target='_blank'>
      <button className='githublink__button button-transparent' onMouseEnter={delayedHover} onMouseLeave={delayedNotHover}>
        <img src={onHover ? githubNavy : githubGold} className='githublink__icon' alt='github icon'/>
        <div className={onHover ? 'githublink__text-hover' : 'githublink__text'}>View on GitHub</div>
      </button>
    </Link>
  )
}

export default GitHubLink