//child of Experience -> displayed on mobile, hidden on tablet and desktop
import React from 'react';
import './ExperienceDetails.scss';

function ExperienceDetails({ experience, experienceDetailsClass }) {
  return (
    <div className={experienceDetailsClass}>
      <p className='experiencedetails__title'>{experience.title}</p>
      <p className='experiencedetails__date'>{experience.date}</p>
      <ul className='experiencedetails__ul'>
        {experience.description.map((description, index) => (
          <li className='experiencedetails__li' key={index}>{description}</li>
        ))}
      </ul>
    </div>
  )
}

export default ExperienceDetails