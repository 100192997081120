//child of SidebarAuthorContact and SocialSide
import React from 'react'
import './Socials.scss'

import { getSocials } from '../../helpers/utils';

import SocialIcon from '../SocialIcon/SocialIcon';
import EmailIcon from '../EmailIcon/EmailIcon';

function Socials({ author, closeContent, socialsClass, color, showEmail, socialIconLinkClass, socialIconImageClass }) {

    const socials = getSocials(author.tag, color)

    return (
        <div className={socialsClass}>
            {socials.map((social, index) => (
                <SocialIcon socialIcon={social.icon} socialLink={social.link} altText={social.alt} key={index} socialIconLinkClass={socialIconLinkClass} socialIconImageClass={socialIconImageClass} />
            ))}
            {showEmail ? <EmailIcon socialLink={author.tag === "JoaoCamara" ? "/joaocamara/#author-contact-form" : "/thaiscampos/#author-contact-form"} closeContent={closeContent} /> : null}
            
        </div>
    )
}

export default Socials