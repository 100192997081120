//child of Individual Project page -> Details section
import React from 'react'
import './ProjectFeatures.scss'

function ProjectFeatures({ featureTitle, featureItems }) {

  return (
    <div className='projectfeatures'>
      <h3 className='projectfeatures__header'>{featureTitle}</h3>
      < ul >
        {featureItems.map((item, index) => {
          return (
            <li key={index} className='projectfeatures__li'>{item}</li>
          );
        })}
      </ul >
    </div >
  )
}

export default ProjectFeatures