import React from 'react';
import './Footer.scss';
import FooterAuthorSocials from '../FooterAuthorSocials/FooterAuthorSocials';
import authors from '../../data/authors.json'

import backToTop from '../../assets/icons/back-to-top.svg'
import { Link } from 'react-router-dom';

function Footer({ myRef }) {

  return (
    <footer className='footer' ref={myRef}>
      <Link to='./' className='footer__back-to-top'>
        <img src={backToTop} className='footer__back-to-top-image' alt='chevron inside of a circle pointing up'/>
      </Link>
      <p className='footer__header'>Designed and Developed by</p>
      <div className='footer__footerauthorsocials'>
        {authors.map((author, index) => (
          <FooterAuthorSocials author={author} key={index} />
        ))}
      </div>
      <p className='footer__comment'>This website was designed and developed without the use of any front-end UI libraries.</p>
    </footer>
  )
}

export default Footer