//About section in Home page
import React from 'react';
import "./About.scss";

import AuthorCard from '../AuthorCard/AuthorCard';
import authors from "../../data/authors.json"

function About() {

    return (
        <div className='about'>
            <div className='about__authors'>
                {authors.map((author, index) => (
                    <AuthorCard key={index} author={author} />
                ))}
            </div>
        </div>
    )
}

export default About