//child of Home page
import React from 'react';
import "./Header.scss";
import Logo from '../Logo/Logo';
import HeaderMenuButton from './HeaderMenuButton/HeaderMenuButton';
import Navbar from '../Navbar/Navbar';

function Header({ navItems }) {
  return (
    <header className="header">
      <div className='header__content'>
        <HeaderMenuButton navItems={navItems} />
        <Logo />
        <div className='placeholder'></div>
        <Navbar navItems={navItems} />
      </div>
    </header>
  )
}

export default Header