//project banner in individual Project page
import React from 'react';
import { useWindowSize } from '../../helpers/useWindowSize.js';
import './Banner.scss'


function Banner({project}) {
    // check for desktop screen size
    const isDesktop = useWindowSize();

    return (
        <div className='banner'>
            <img src={project.imageLaptop.image} alt={project.imageLaptop.alt} className={`${isDesktop ? 'hidden' : 'banner__image'}`} />
            <img src={project.imageDevices.image} alt={project.imageDevices.alt} className={`${isDesktop ? 'banner__image' : 'hidden'}`} />
        </div>
    )
}

export default Banner