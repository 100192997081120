//child of Projects page -> area where close button and filter options are displayed
import React from 'react';
import './FilterBy.scss';
import FilterByContainer from '../FilterByContainer/FilterByContainer';
import filterIcon from '../../assets/icons/filter.svg'

function FilterBy({ handleFilters, filter, showFilter, closeFilter }) {

    return (
        <div className='filterby' onClick={showFilter} >
            <div className='filterby__text'>Filter Projects by</div>
            <button className="filterby__button" onClick={showFilter}>
                <img src={filterIcon} className="filterby__button-image" alt='menu icon' />
            </button>
            <FilterByContainer filter={filter} closeFilter={closeFilter} handleFilters={handleFilters} />
        </div>
    )
}

export default FilterBy