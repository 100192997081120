import React from 'react';
import "./SideBar.scss";
import Logo from '../Logo/Logo';
import CloseButton from '../CloseButton/CloseButton';
import closeIconGold from "../../assets/icons/close-gold.svg";
import SidebarNav from '../SidebarNav/SidebarNav';
import SidebarAuthorContact from '../SidebarNav/SidebarAuthorContact/SidebarAuthorContact';
import authors from '../../data/authors.json'

function SideBar({ sidebar, showSidebar, navItems }) {

    return (
        <div className={sidebar ? "sidebar active" : "sidebar"} >
            <div className="sidebar__content">
                <div className="sidebar__header">
                    <Logo />
                    <CloseButton closeContent={showSidebar} iconColor={closeIconGold} />
                </div>
                <SidebarNav navItems={navItems} closeContent={showSidebar} />
                <div className='socials'>
                    {authors.map((author, index) => (
                        <SidebarAuthorContact author={author} closeContent={showSidebar} key={index} />
                    ))}
                </div>
            </div>
        </div >
    )
}

export default SideBar