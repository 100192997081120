//child of SideBarNav
import React from 'react'
import './SidebarNavItem.scss'
import { Link } from 'react-router-dom'

function SidebarNavItem({ itemClass, itemName, pathTo, closeContent }) {

    const handleClick = (e) => {
        e.stopPropagation();
        closeContent();
    }

    return (
        <>
            <Link className={itemClass} to={pathTo} onClick={handleClick}>
                {itemName}
            </Link>
        </>
    )
}

export default SidebarNavItem