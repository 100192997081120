import React from 'react';
import { useScrollToSection } from '../../helpers/useScrollToSection';
import "./Home.scss";
import About from '../../components/About/About';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';
import Featured from '../../components/Featured/Featured';
import Header from '../../components/Header/Header';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

function Home() {

    //header nav items in this page
    useScrollToSection();
    const homeNavItems = [
        { itemName: 'Projects', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/projects' },
        { itemName: 'About', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/#about-authors' },
        { itemName: 'Contact', itemClass: 'navbar-item-header', sidebarItemClass: 'sidebar-nav-item', pathTo: '/#home-contact-form' }
    ]


    return (
        <div className='home'>
            <Header navItems={homeNavItems} />
            <SectionTitle sectionTitle={'Featured'} />
            <Featured />
            <SectionTitle sectionTitle={'About Us'} sectionTitleId={'about-authors'} />
            <About />
            <SectionTitle sectionTitle={'Get In Touch'} sectionTitleId={'home-contact-form'} />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home