//child of About component
import React from 'react';
import { useNavigate } from "react-router-dom";
import { getStack } from '../../helpers/utils';
import './AuthorCard.scss';

import AuthorImage from '../AuthorImage/AuthorImage';
import Button from '../Button/Button';
import TechStack from '../TechStack/TechStack';

function AuthorCard({ author }) {

  // get reference to author's profile picture
  const authorPicture = require("../../assets/images/" + author.profilePictureFilename);

  // getting a reference to author's stack
  const techData = getStack(author.stack);

  // handling navigation to author's profile page
  const navigate = useNavigate();
    const goToAuthor = () => {
        navigate(`${author.tag.toLowerCase()}`);
    }

  return (
    <div className='about-author'>
      <div className='about-author__profile'>
        <AuthorImage authorImage={authorPicture} authorImageImgClass={'author-image__img'} authorImageClass={'author-image'} />
        <h2 className='about-author__name'>{author.name}</h2>
        <h3 className='about-author__title'>{author.title}</h3>
      </div>
      <div className='about-author__right-container'>
        <TechStack stackContent={techData} techstackExtraClass={'about-author__techstack'} />
        <Button className={'button-transparent'} buttonText={'More About Me'} action={goToAuthor} />
      </div>
    </div>
  )
}

export default AuthorCard