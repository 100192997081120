import React from 'react';
import './SelectField.scss';
import OptionField from '../OptionField/OptionField';

function SelectField(props) {

    const handleChange = (event) => {
        props.setState(event.target.value);
    };


    return (
        <>
            <select className={props.selectFieldClassName} placeholder={props.placeholderSelectField} onChange={handleChange} defaultValue={props.state}>
                <OptionField value={''} textValue={props.placeholderSelectField} hidden={props.hideFirstOption} />
                {props.options.map(option => (
                    <OptionField key={option.value} value={option.value} textValue={option.text} />
                ))}
            </select>
        </>
    )
}

export default SelectField