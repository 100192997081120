// featured section of the Home page
import React from 'react';
import { useNavigate } from "react-router-dom";
import './Featured.scss';
import Carousel from '../Carousel/Carousel';
import Button from '../Button/Button';

function Featured() {

    // handling navigation to a individual project page
    const navigate = useNavigate();
    const goToProjects = () => {
        navigate('/projects');
    }

    return (
        <div className='featured'>
            <Carousel />
            <Button className={'button-gold featured__button'} buttonText={'View All Projects'} action={goToProjects} />
        </div>
    )
}

export default Featured