//child of Author page -> hidden on mobile, displayed in tablet and desktop
import React from 'react'
import './ExperienceDetailsSide.scss'

function ExperienceDetailsSide({ experience }) {
  return (
    <div className='experiencedetailsside-tablet'>
      <p className='experiencedetailsside__title'>{experience.title}</p>
      <p className='experiencedetailsside__date'>{experience.date}</p>
      <ul className='experiencedetailsside__ul'>
        {experience.description.map((description, index) => (
          <li className='experiencedetailsside__li' key={index}>{description}</li>
        ))}
      </ul>
    </div>
  )
}

export default ExperienceDetailsSide