import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

import './Contact.scss';
import Input from '../Input/Input';
import TextArea from '../TextArea/TextArea';
import SelectField from '../SelectField/SelectField';
import Button from '../Button/Button';

import contacts from "../../data/contact.json";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact({sendToOption}) { 

    //create send options to select field
    const sendOptions = [];
    for (let contact of contacts) {
        const option = { value: contact.tag, text: contact.name };
        sendOptions.push(option);
    }

    //form inputs references
    const [senderName, setSenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [sendTo, setSendTo] = useState(sendToOption ? sendToOption : '');
    const [message, setMessage] = useState('');

    //valid input checks references
    const [validSenderName, setValidSenderName] = useState(false);
    const [validSenderEmail, setValidSenderEmail] = useState(false);
    const [validSendTo, setValidSendTo] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const [showErrors, setShowErrors] =useState(false);


    //individual inputs validation
    //check sender name
    const validateSenderName = () => { setValidSenderName(!senderName ? false : true) };
    //check sender email
    const validateSenderEmail = () => { setValidSenderEmail(!senderEmail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? false: true )};
    //check sendTo
    const validateSendTo = () => { setValidSendTo(!sendTo ? false : true) };
    //check message
    const validateMessage = () => { setValidMessage(!message ? false : true) };

    useEffect(() => {
        //check sender name
        validateSenderName();
        //check sender email
        validateSenderEmail();
        //check sendTo
        validateSendTo();
        //check message
        validateMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderName, senderEmail, sendTo, message]);

    const validForm = () => {
        if(validSenderName && validSenderEmail && validSendTo && validMessage) {
            setShowErrors(false);
            return true;
        }
        else {
            setShowErrors(true);
            return false;
        }
    }

    //form reset -> successful submission
    const resetForm = (e) => {
        setSenderName('');
        setSenderEmail('');
        setSendTo('');
        setMessage('');
        e.target.reset();
    }



    //handling form submission
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(sendTo)
        if (validForm()) {
            const serviceId = contacts.find(contact => contact.tag === sendTo).emailServiceId;
            const key = contacts.find(contact => contact.tag === sendTo).emailServiceKey;

            emailjs.sendForm(serviceId, 'contact_portfolio', e.target, key)
                .then((result) => {
                    toast.success('Message sent!', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resetForm(e);
                })
                .catch((error) => {
                    toast.error('Sorry, an error occurred', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        }
    };

    return (
        <div className='contact'>
            < ToastContainer />
            <form className='contact__form' onSubmit={sendEmail}>
                <p hidden={!showErrors} className='contact__form-validation-message'>All fields are required!</p>
                <div className='contact__form--tablet'>
                    <Input
                        InputClassName={`contact__form-fields contact__form-fields--tablet-left ${!validSenderName && showErrors ? 'contact__form-fields--error' : ''}`}
                        placeholderText={'Name'}
                        name={'from_name'}
                        state={senderName}
                        setState={setSenderName}
                        validationState={validSenderName}
                        validateInput={validateSenderName}
                    />
                    <Input
                        InputClassName={`contact__form-fields contact__form-fields--tablet-right ${!validSenderEmail && showErrors ? 'contact__form-fields--error' : ''}`}
                        placeholderText={'Email'}
                        name={'reply_to'}
                        state={senderEmail}
                        setState={setSenderEmail}
                        validationState={validSenderEmail}
                        validateInput={validateSenderEmail}
                    />
                </div>
                {
                    !sendToOption ?
                    <SelectField
                    selectFieldClassName={`contact__form-fields ${!validSendTo && showErrors ? 'contact__form-fields--error' : ''} ${!sendTo ? 'contact__form-fields--select' : ''}`}
                    placeholderSelectField={'Address to'}
                    options={sendOptions}
                    name={'to_name'}
                    state={sendTo}
                    setState={setSendTo}
                    validationState={validSendTo}
                    validateInput={validateSendTo}
                    hideFirstOption={true}
                    />
                    :
                    null
                }
        
                <TextArea
                    textAreaClassName={`contact__form-fields contact__textarea ${!validMessage && showErrors ? 'contact__form-fields--error' : ''}`}
                    placeholderTextArea={'Your Message'}
                    name={'message'}
                    state={message}
                    setState={setMessage}
                    validationState={validMessage}
                    validateInput={validateMessage}
                />
                <div className='contact__button'>
                    <Button buttonText={'Send'} type={'submit'} className='contact__button--tablet' />
                </div>

            </form>
        </div>
    )
}

export default Contact