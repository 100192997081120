//child of MediaCarouselSlide
import React from 'react'
import './MediaCarouselSlide.scss'

function MediaCarouselSlide({ mediaCarouselSlideImage, current }) {
    return (
        <div className={mediaCarouselSlideImage}>
            <img src={current.image} className={mediaCarouselSlideImage} alt={current.alt} />
        </div>
    )
}

export default MediaCarouselSlide